import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H2, Paragraph } from "../../styles/TextStyles"

interface IntroIntroMissionProps {}

const IntroMission: FunctionComponent<IntroIntroMissionProps> = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Our brand</Title>
          <Description>
            Joshua Organisation is a service provider company based in Cape
            Town. We specialize in providing support in Maritime Training, Visa
            services, Education, Transportation, Accommodation, and Visa
            Services. We connect you to the best maritime training solutions
            which enfold all facets of Human Resource Development and
            Consultancy. All our partner providers are SAMSA (STCW) accredited,
            and OPITO recognized.
          </Description>
        </TextWrapper>
        <TextWrapper>
          <Title>Mission Statement</Title>
          <Description>
            Our mission is finding the best solutions for education and training
            for the development of Human Resources. We want to be the connection
            that links education and your employees.
          </Description>
        </TextWrapper>
        <TextWrapper>
          <Title>Objectives</Title>
          <Description>
            Our objective is to fulfill all your needs, finding for your company
            the best and most affordable accommodation to stay, accredited
            institution with a variety of training, including STCW-Training
            programs and education for your personal, providing you
            transportation to take you wherever you desire, while we take care
            of your staying and your health.
          </Description>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default IntroMission

const Wrapper = styled.div`
  padding: 100px 80px 100px 80px;
`
const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 120px;
`
const TextWrapper = styled.div`
  display: grid;
  gap: 30px;
`
const Title = styled(H2)`
  font-weight: bold;
`
const Description = styled(Paragraph)`
  max-width: 740px;
  line-height: 138%;
`
