import React, { FunctionComponent } from "react"
import ReviewCard from "../components/cards/ReviewsCard"
import IntroSection from "../components/IntroSection"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import ReviewsHeading from "../components/sections/Heading"
import IntroMission from "../components/sections/IntroMission"
import MemberShipSection from "../components/sections/MemberShipSection"

interface IndexPageProps {
  title: string
  name: string
  content: string
}

const IndexPage: FunctionComponent<IndexPageProps> = props => {
  const { title = "Home", name = "Keywords", content = "Education" } = props
  return (
    <Layout>
      <SEO title={title} />
      <meta name={name} content={content} />
      <IntroSection />
      <IntroMission />
      <MemberShipSection />
      <ReviewsHeading />
      <ReviewCard />
    </Layout>
  )
}

export default IndexPage
