import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../../styles/TextStyles"

interface ReviewsHeadingProps {}

const ReviewsHeading: FunctionComponent<ReviewsHeadingProps> = () => {
  return (
    <Wrapper>
      <Title>Trusted by hundreds of customers</Title>
      <Subtitle>See why Joshua Organisation has over 25 star reviews.</Subtitle>
    </Wrapper>
  )
}

export default ReviewsHeading

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  margin: 0 0 80px 0;
`
const Title = styled(H1)`
  line-height: 230%;
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 30px;
  }
`
const Subtitle = styled(Paragraph)`
  line-height: 130%;
  text-align: center;
`
