import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../../styles/TextStyles"

interface MemberShipSectionProps {}

const MemberShipSection: FunctionComponent<MemberShipSectionProps> = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Certified by ISO</Title>
          <Description>
            Our company is registered by international organisations in
            cooperation with ISO.
          </Description>
        </TextWrapper>
        <GaleryImage>
          <Image src="/assets/icons/iso1.jpeg" />
          <Image src="/assets/icons/Iso2.jpeg" />
          <Image src="/assets/icons/Iso3.jpeg" />
        </GaleryImage>
      </ContentWrapper>
    </Wrapper>
  )
}

export default MemberShipSection

const Wrapper = styled.div`
  padding: 100px 80px 100px 80px;
`
const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 120px;
`
const TextWrapper = styled.div`
  display: grid;
  gap: 30px;
`
const Title = styled(H1)`
  font-weight: bold;
`
const Description = styled(Paragraph)`
  max-width: 740px;
  line-height: 138%;
`
const GaleryImage = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;

  @media (max-width: 1024px) {
    grid-template-columns: 200px 200px 200px;
  }
  grid-template-columns: 300px 300px 300px;
  @media (max-width: 768px) {
    grid-template-columns: 200px 200px 200px;
  }
  grid-template-columns: 300px 300px 300px;
  @media (max-width: 640px) {
    grid-template-columns: 300px;
  }
  gap: 60px;
`

const Image = styled.img`
  width: 300px;
  @media (max-width: 1024px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
`
