import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H1, Paragraph } from "../styles/TextStyles"

interface IntroSectionProps {}

const IntroSection: FunctionComponent<IntroSectionProps> = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Think Big</Title>
          <Description>Think Joshua Organisation</Description>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default IntroSection

const Wrapper = styled.div`
  padding: 200px 80px 100px 80px;
`
const ContentWrapper = styled.div`
  display: grid;
  justify-content: center;
`
const TextWrapper = styled.div`
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #fff;
    }
  }
  @keyframes typing {
    0% {
      width: 0%;
    }
    30% {
      width: 100%;
    }
    80% {
      width: 100%;
    }
    90% {
      width: 0%;
    }
    100% {
      width: 0%;
    }
  }

  display: grid;
  gap: 30px;
`
const Title = styled(H1)`
  font-weight: bold;
`
const Description = styled(Paragraph)`
  font-size: 22px;

  white-space: nowrap;
  overflow: hidden;
  animation: typing 15s steps(300) forwards, blink-caret 0.15s step-end infinite;
  animation-iteration-count: infinite;
`
